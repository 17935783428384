<template>
  <v-container fill-height>
    <v-layout justify-center>
      <v-flex xs12 sm12 md12 lg11 xl8>
        <v-form class="elevation-5">
          <template>
            <v-toolbar color="primary" dark dense>
              <v-icon class="mr-2">mdi-account-group-outline</v-icon>
              <v-toolbar-title>{{ tituloForm }}</v-toolbar-title>
              <v-spacer></v-spacer>

              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    class="mr-2"
                    v-bind="attrs"
                    v-on="on"
                    @click="metClear"
                  >
                    mdi-refresh
                  </v-icon>
                </template>
                <span>Refresh</span>
              </v-tooltip>

              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    class="mr-2"
                    v-bind="attrs"
                    v-on="on"
                    @click="metVoltar()"
                  >
                    mdi-arrow-left-circle-outline
                  </v-icon>
                </template>
                <span>Voltar</span>
              </v-tooltip>
            </v-toolbar>
          </template>
          <!-- :error-messages="nomeErrors" 
                              required
                  @input="$v.conveniadaLocal.nome_conveniada.$touch()"
                  @blur="$v.conveniadaLocal.nome_conveniada.$touch()"-->

          <template>
            <v-container fluid>
              <v-row dense>
                <v-col cols="auto" md="1" align-self="center">
                  <v-text-field
                    hide-details="auto"
                    v-model="conveniadaLocal.id"
                    label="ID"
                    readonly
                    outlined
                  ></v-text-field>
                </v-col>
                <v-col cols="auto" md="4" align-self="center">
                  <v-text-field
                    hide-details="auto"
                    v-model="conveniadaLocal.alias"
                    label="Alias"
                    outlined
                  ></v-text-field>
                </v-col>
                <v-col cols="auto" md="7" align-self="center">
                  <v-text-field
                    hide-details="auto"
                    v-model="conveniadaLocal.razaosocial"
                    label="Razão Social"
                    outlined
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row dense>
                <v-col cols="auto" md="3" align-self="center">
                  <v-text-field
                    hide-details="auto"
                    v-model="conveniadaLocal.cnpj"
                    label="CNPJ"
                    outlined
                  ></v-text-field>
                </v-col>
                <v-col cols="auto" md="2" align-self="center">
                  <v-text-field
                    hide-details="auto"
                    v-model="conveniadaLocal.cep"
                    label="CEP"
                    outlined
                  ></v-text-field>
                </v-col>
                <v-col cols="auto" md="7" align-self="center">
                  <v-text-field
                    hide-details="auto"
                    v-model="conveniadaLocal.endereco"
                    label="Endereço"
                    outlined
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row dense>
                <v-col cols="auto" md="4" align-self="center">
                  <v-text-field
                    hide-details="auto"
                    v-model="conveniadaLocal.bairro"
                    label="Bairro"
                    outlined
                  ></v-text-field>
                </v-col>
                <v-col cols="auto" md="6" align-self="center">
                  <v-text-field
                    hide-details="auto"
                    v-model="conveniadaLocal.cidade"
                    label="Cidade"
                    outlined
                  ></v-text-field>
                </v-col>
                <v-col cols="auto" md="2" align-self="center">
                  <v-text-field
                    hide-details="auto"
                    v-model="conveniadaLocal.uf"
                    label="UF"
                    outlined
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row dense>
                <v-col cols="auto" md="4" align-self="center">
                  <v-text-field
                    hide-details="auto"
                    v-model="conveniadaLocal.fones"
                    label="Fones"
                    outlined
                  ></v-text-field>
                </v-col>
                <v-col cols="auto" md="2" align-self="center">
                  <v-text-field
                    hide-details="auto"
                    v-model="conveniadaLocal.desconto"
                    label="Desconto"
                    outlined
                  ></v-text-field>
                </v-col>
                <v-col cols="auto" md="2" align-self="center">
                  <v-text-field
                    hide-details="auto"
                    v-model="conveniadaLocal.encerramento"
                    label="Dia do Encerramento"
                    outlined
                  ></v-text-field>
                </v-col>
                <v-col cols="auto" md="4" align-self="center">
                  <v-text-field
                    hide-details="auto"
                    v-model="conveniadaLocal.responsavel"
                    label="Responsável"
                    outlined
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-container>
          </template>

          <template>
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  class="ml-4 mr-4 mb-4"
                  color="green darken-1"
                  v-bind="attrs"
                  v-on="on"
                  @click="submit"
                >
                  mdi-check-circle
                </v-icon>
              </template>
              <span>Salvar</span>
            </v-tooltip>

            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  class="mr-4 mb-4"
                  color="red darken-1"
                  v-bind="attrs"
                  v-on="on"
                  @click="metVoltar()"
                >
                  mdi-backspace
                </v-icon>
              </template>
              <span>Cancelar</span>
            </v-tooltip>
          </template>
        </v-form>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
import { validationMixin } from "vuelidate";
// import { required, email } from "vuelidate/lib/validators";
import DatasMixin from "@/mixins/DatasMixin";

const { mapState, mapActions } = createNamespacedHelpers("lojaConveniadas");

export default {
  mixins: [validationMixin, DatasMixin],
  // components: { tableCompras },
  // validations: {
  //   conveniadaLocal: {
  //     nome_conveniada: { required },
  //     email: { required, email },
  //     conveniada: { required },
  //     senha: { required },
  //     ativo: { required },
  //   },
  // },

  data: () => ({
    name: "LojaConveniadasForm",
    tituloForm: "",
    conveniadaLocal: {},
    comprasIconDisable: false,
    selectStatus: ["Aguardando", "Andamento", "Concluida", "Arquivada"],
  }),

  computed: {
    ...mapState(["conveniadaSelect", "listaUsuarios"]),
    // nomeErrors() {
    //   const errors = [];
    //   if (!this.$v.conveniadaLocal.nome_conveniada.$dirty) return errors;
    //   !this.$v.conveniadaLocal.nome_conveniada.required &&
    //     errors.push("Nome requerido.");
    //   return errors;
    // },
    // emailErrors() {
    //   const errors = [];
    //   if (!this.$v.conveniadaLocal.email.$dirty) return errors;
    //   !this.$v.conveniadaLocal.email.email && errors.push("E-mail inválido");
    //   !this.$v.conveniadaLocal.email.required && errors.push("E-mail requerido");
    //   return errors;
    // },
    // conveniadaErrors() {
    //   const errors = [];
    //   if (!this.$v.conveniadaLocal.conveniada.$dirty) return errors;
    //   !this.$v.conveniadaLocal.conveniada.required &&
    //     errors.push("Usuário requerido.");
    //   return errors;
    // },
    // senhaErrors() {
    //   const errors = [];
    //   if (!this.$v.conveniadaLocal.senha.$dirty) return errors;
    //   !this.$v.conveniadaLocal.senha.required && errors.push("Senha requerida.");
    //   return errors;
    // },
  },

  watch: {
    conveniadaSelect(conveniadaNovo) {
      this.sincronizar(conveniadaNovo);
    },
  },

  created() {
    this.sincronizar(this.conveniadaSelect);
    if (this.conveniadaSelect) {
      this.tituloForm = "Editar Conveniada";
    } else {
      this.tituloForm = "Nova Conveniada";
    }
    // this.formataDatasDB();
  },
  methods: {
    ...mapActions([
      "selecionarConveniada",
      "resetarConveniada",
      "criarConveniada",
      "editarConveniada",
      "resetarErro",
    ]),

    obterUsuario(id) {
      if (id != null) {
        const ret = this.listaUsuarios.filter((g) => g.id == id);
        return ret[0].usuario;
      } else {
        const ret = "";
        return ret;
      }
    },

    selecionarConveniadaEdicao(conveniada) {
      this.selecionarConveniada({ conveniada });
    },

    async salvarConveniada() {
      switch (this.conveniadaSelect) {
        case undefined:
          this.conveniadaLocal.cria_usuario =
            this.$store.state.login.usuario.id;
          this.conveniadaLocal.cria_datahora = this.mixDataAgoraBR();
          this.formataDatasForm();
          await this.criarConveniada({
            conveniada: this.conveniadaLocal,
            licenca: this.$store.state.login.licenca,
          });
          this.formataDatasDB();
          if (this.$store.state.lojaConveniadas.erro != undefined) {
            this.$root.snackbar.show({
              type: "danger",
              message: this.$store.state.lojaConveniadas.erro,
            });
            this.resetarErro();
          } else {
            this.$root.snackbar.show({
              type: "success",
              message: "Conveniada Criada!!!",
            });
          }
          break;
        default:
          this.conveniadaLocal.mod_usuario = this.$store.state.login.usuario.id;
          this.conveniadaLocal.mod_datahora = this.mixDataAgoraBR();
          this.formataDatasForm();
          await this.editarConveniada({
            conveniada: this.conveniadaLocal,
            licenca: this.$store.state.login.licenca,
          });
          this.formataDatasDB();
          if (this.$store.state.lojaConveniadas.erro != undefined) {
            this.$root.snackbar.show({
              type: "danger",
              message: this.$store.state.lojaConveniadas.erro,
            });
            this.resetarErro();
          } else {
            this.$root.snackbar.show({
              type: "success",
              message: "Conveniada Salva!!!",
            });
          }
      }
      //this.resetar();
    },

    formataDatasForm() {
      this.conveniadaLocal.cria_datahora = this.mixDataFormD(
        this.conveniadaLocal.cria_datahora
      );
      // console.log("1", this.conveniadaLocal.mod_datahora);
      this.conveniadaLocal.mod_datahora = this.mixDataFormD(
        this.conveniadaLocal.mod_datahora
      );
      // console.log("2", this.conveniadaLocal.mod_datahora);
    },

    formataDatasDB() {
      this.conveniadaLocal.cria_datahora = this.mixDataDBD(
        this.conveniadaLocal.cria_datahora
      );
      this.conveniadaLocal.mod_datahora = this.mixDataDBD(
        this.conveniadaLocal.mod_datahora
      );
    },

    sincronizar(novoConveniada) {
      this.conveniadaLocal = Object.assign(
        {},
        novoConveniada || {
          id: null,
          // cria_datahora: null,
          // mod_datahora: null,
        }
      );
    },
    submit() {
      // this.$v.$touch();
      this.salvarConveniada();
      // this.$router.back();
    },
    metClear() {
      //this.$v.$reset();
      // this.conveniadaLocal.ultima_compra = this.conveniadaSelect.ultima_compra;
      // this.conveniadaLocal.email = this.conveniadaSelect.email;
      // this.conveniadaLocal.conveniada = this.conveniadaSelect.conveniada;
      // this.conveniadaLocal.senha = this.conveniadaSelect.senha;
      // this.conveniadaLocal.ativo = this.conveniadaSelect.ativo;
    },
    metVoltar() {
      this.$router.back();
    },
  },
};
</script>
